import { toggleClass, hasClass } from '../shared/easyfy.utils';
import { events } from '../shared/easyfy.decorators';
import { globalEvent } from '../shared/easyfy.core';

export default class ReadMore {
  constructor() {
    globalEvent({
      type: 'click',
      event: {
        event: 'read-more',
        callback: this.onReadMore,
      },
    });

    document.querySelectorAll('.expandable.readmore-compact').forEach((el: HTMLElement) => {
      el.parentElement.style.position = 'relative';
    });
  }

  onReadMore(e: Event) {
    e.preventDefault();
    const _this: HTMLElement = e.target as HTMLElement;
    const previousElement: HTMLElement = _this.parentElement.previousElementSibling as HTMLElement;

    if (previousElement) {
      toggleClass(previousElement, 'expanded');

      if (hasClass(previousElement, 'expanded')) {
        if (!_this.dataset.maxHeight) {
          previousElement.dataset.maxHeight = previousElement.style.maxHeight;
        }
        _this.textContent = _this.attributes['data-less']?.value;
      } else {
        previousElement.style.maxHeight = previousElement.dataset.maxHeight;
        _this.textContent = _this.attributes['data-more']?.value;
      }
    }
    return false;
  }
}
